import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../../../styles/core.css"
import "../../../styles/styles.css"
import Layout from "../../../components/Layout"
import MenuOne from "../../../components/MenuOne"
import MenuTwo from "../../../components/MenuTwo"
import MenuThree from "../../../components/MenuThree"
import Content from "../../../components/Content"
import Gallery from "../../../components/Gallery"

const galImgs = ['/page-images/vivomed_1.jpg', '/page-images/dscn5598.jpg']

export default function OurWork() {
  return (
    <Layout breadcrumbs={["Vivomed Offices, Downpatrick"]}>
      <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )}
    />

<Content>
<p>Our client brief was to provide a modern assembly and administration premises for their medical kit business. While the building is significant in scale, it was designed.and constructed to incorporate practical renewable energy systems such as solar water heating, a wood pellet boiler system and a 6kW wind turbine.</p>

<p>	The result is a contemporary building and the first building of its type in Northern ireland to achieve low-energy accreditation</p>
</Content>

      <div class="region region-content-bottom">
        <MenuOne active='our-work' />
        <MenuTwo
          active=""
          items={[
            '/our-work/residential',
            '/our-work/commercial',
            '/our-work/industrial',
            '/our-work/leisure',
            '/our-work/community',
            '/our-work/retail',
          ]} />
        <MenuThree
          items={[
            '/our-work/commercial/vivomed-offices-downpatrick',
            '/our-work/commercial/boc-offices-belfast',

          ]} />
      </div>

  </Layout>

  )
}
